import React from 'react'
import PropTypes from 'prop-types'
import remark from 'remark'
import remarkHtml from 'remark-html'
import recommended from 'remark-preset-lint-recommended'
import ImageGallery from 'react-image-gallery';
import { HTMLContent } from '../components/Content'

import { normalizeObjectList, getImageUrl } from '../util'

export default class Locations extends React.Component {
  constructor(props) {
    super(props)
    const { locationData } = props;
    if (!locationData) return;

    const locationsList = normalizeObjectList(locationData, [
      'id', 'title', 'subtitle', 'description', 'editionId', 'priority', 'anchor', 'featuredImages',
      { location: ['name', 'address', 'search'] },
    ], 'id');

    const locations = Object.keys(locationsList)
      .filter((key) => locationsList[key].name !== '')
      .map((key) => {
        return locationsList[key]
      }).sort((a, b) => a.priority - b.priority);

    this.state = { locations: locations }
  }

  render() {

    return (
      <div>
        {this.state.locations.map((location) => {
          const description = remark()
            .use(recommended)
            .use(remarkHtml)
            .processSync(location.description)
            .toString()

          const images = location.featuredImages.map((image, idx) => {
            return { original: getImageUrl(image.name), originalAlt: image.altText ? image.altText : '' }
          })

          return (
            <div key={location.id} ref={(section) => { if (this.currentLocation === location) this.currentLocation.div = section }} id={location.anchor}>
              <h2 className="text-center">{location.title}</h2>
              <h3 className="text-center">{location.subtitle ? location.subtitle : ""}</h3>

              <ImageGallery
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                showThumbnails={false}
                showBullets={true}
                autoPlay={true}
                lazyLoad={true}
                slideInterval={4000}
                renderLeftNav={
                  (onClick, disabled) => {
                    return (
                      <button
                        aria-label="previous image"
                        className="carousel-nav left-nav"
                        disabled={disabled}
                        onClick={onClick}
                      >
                        <i className="fa fa-chevron-left left-chevron" />
                      </button>
                    )
                  }
                }
                renderRightNav={
                  (onClick, disabled) => {
                    return (
                      <button
                        aria-label="next-image"
                        className="carousel-nav right-nav"
                        disabled={disabled}
                        onClick={onClick}
                      >
                        <i className="fa fa-chevron-right right-chevron" />
                      </button>
                    )
                  }
                }
              />

              {location.address ? <p>{location.address} (<a href={`http://maps.google.com/maps?q=${encodeURIComponent(location.search)}`} target="_blank" rel="noopener noreferrer">map</a>)</p> : ""}
              <HTMLContent content={description} />
            </div>
          )
        })}
      </div>
    )
  }
}

Locations.propTypes = {
  data: PropTypes.array,
}