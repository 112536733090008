import { graphql, Link } from 'gatsby'
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet'

import Layout from '../components/layout';
import Locations from '../components/locations'

//TODO: fix content to show loactions
export default class EditionLocationPage extends React.Component {

  render() {
    const { data, location } = this.props

    const anchorTypes = [{ name: 'None', val: 'none' },
    { name: 'Venue', val: 'venue' },
    { name: 'Hotel', val: 'hotel' },
    { name: 'Travel Tips', val: 'tips' },]

    if (!data || !data.festivalInfrastructure) return null;

    let { edges: festivalInfrastructure } = data.festivalInfrastructure
    const edition = data.edition
    const editionId = this.props.pathContext.editionId

    let currentFestivalInfrastructure = festivalInfrastructure.filter(x => x.node.editionId === editionId)

    const anchors = currentFestivalInfrastructure.reduce((acc, elem) => {
      let anchor = elem.node.anchor
      if (anchor && !acc.includes(anchor) && anchor !== 'none') {
        acc.push(anchor)
      }
      return acc
    }, [])

    const anchorLinks = anchorTypes.reduce((acc, elem) => {
      if (anchors.includes(elem.val)) {
        acc.push(elem)
      }
      return acc
    }, [])

    return (
      <Layout>
        <Helmet title={`${location.pathname.slice(-14) === 'program-venues' ? 'Program' : 'Venues'} | ${edition.name}`} />
        <section className="locations-container" id="locations">
          <h1 className="page-header">{edition.name} {location.pathname.slice(-14) === 'program-venues' ? 'Program' : 'Venues'}</h1>

          {location.pathname.slice(-14) === 'program-venues' ? (
            <nav aria-label="program" className="program-menu">
              <li><Link to={`${edition.fields.slug}program-meetups`}><span>Meetups</span></Link></li>
              <li><Link to={`${edition.fields.slug}program-workshops`}><span>Workshops</span></Link></li>
              <li><Link to={`${edition.fields.slug}program-conference`}><span>Conference</span></Link></li>
            </nav>) : ''}
          <nav aria-label="page" className="program-menu anchor-menu">
            {anchorLinks.map((anchor, idx, arr) => {
              return (
                <span key={idx}>
                  <Link to={`/${location.pathname}#${anchor.val}`}><span>{anchor.name}</span></Link>
                </span>
              )
            })}
          </nav>
          {festivalInfrastructure.length > 0 && <Locations locationData={currentFestivalInfrastructure} edition={editionId} />}
        </section>
      </Layout>
    )
  }
}

EditionLocationPage.propTypes = {
  data: PropTypes.shape({
    allFluxibleSpeakers: PropTypes.shape({
      edges: PropTypes.array,
    }),
    fluxibleEditions: PropTypes.object,
  }),
}

export const editionInfrastructureQuery = graphql`
query EditionInfrastructureQuery($editionId: Int!) {
  festivalInfrastructure: allFluxibleFestivalInfrastructure {
    edges {
      node {
        id
        title
        subtitle
        description
        anchor
        location {
          name
          address
          room
          search
        }
        editionId
        priority
        featuredImages {
          name
          contentType
          altText
        }
      }
    }
  }
  edition: fluxibleEditions(alternative_id: { eq: $editionId }
    ) {
    name
    startDate
    endDate
    fields {
      slug
    }
  }
}
`